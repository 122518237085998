// Insert your styling here.

.facetapi-facetapi-links {
  padding: 0;
}
/*

a, button {
  transition: 0.4s;
  -webkit-transition: 0.4s;

  &:hover {
    text-decoration: none;
  }

  &[role=button]{
    cursor: pointer;
  }

}
*/
h1, h2, h3, h4, h5, h6 {
  &:hover, a:hover {
    text-decoration: none;
  }
}

.headline-container {
  margin-top: 30px;
  .btn-breadcrumb-ply {
    .btn {
      font-size: 12px;
    }
  }

  .btn-breadcrumb {
    & > .btn{
      &:hover, &:focus, &:active{
        z-index: auto;
        box-shadow: none;
        &:after{
          border-left: 10px solid #e6e6e6 !important;
        }
      }
      &.active{
        &:hover, &:focus, &:active{
          z-index: auto;
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }
      }
    }
  }

  .ply-breadcrumb {
    background: @navbar-default-bg;
    border-bottom: 1px solid #9ab6ba;
    .clearfix;
    text-transform: uppercase;
    font-size: 11px;
    .breadcrumbs {
      zoom: 1.2;
      overflow: hidden;
      a {
        display: inline-block;
        padding: 15px 12px 15px 24px;
        text-align: center;
        vertical-align: middle;
        position: relative;
        float: left;
      }
      a:not(:last-child):after {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
        border-left: 10px solid white;
        position: absolute;
        top: 50%;
        margin-top: -17px;
        left: 100%;
        z-index: 3;
      }
      a:not(:last-child):before {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
        border-left: 10px solid rgb(173, 173, 173);
        position: absolute;
        top: 50%;
        margin-top: -17px;
        margin-left: 1px;
        left: 100%;
        z-index: 3;
      }
      a:first-child {
        padding:6px 6px 6px 10px;
      }
      a:last-child {
        padding:6px 18px 6px 24px;
      }
      a:not(:last-child):after {
        border-left: 10px solid @navbar-default-bg;
      }
      a:not(:last-child):before {
        border-left: 10px solid #9ab6ba;
      }
      a:hover:not(:last-child):after {
        border-left: 10px solid @navbar-default-bg;
      }
      a:hover:not(:last-child):before {
        border-left: 10px solid #9ab6ba;
      }
    }
  }
  margin-bottom: 20px;
}

.page-manage {
  .main-container {
    margin-top: 30px;
  }

  .headline-container {
    margin-bottom: 0px;
  }
}

h2.pane-title {
  //margin-top: 0;
  font-size: @font-size-h4;
}

.pane-node-form-buttons.well {
  margin-top: 20px;
}

.page-manage-c {
  .right {
    .pane-custom.pull-right {
      margin: 20px 0;
    }
  }
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-left: 0px !important;
}

.radio,
.form-type-radio,
.checkbox,
.form-type-checkbox {
  label {
    padding-left: 26px;
  }
}

.options-element-processed {
  .form-options-manual,
  .option-settings {
    display: none;
  }
}

#edit-actions{
  margin-top: 30px;
  .form-submit{
    margin-right: 10px;
  }
}

/* Manage company */
.bootswatch-preview {
  img {
    width: 500px;
    height: auto;
    max-width: 100%;
  }

}

/**
 * Applications search
 */
.pane-facetapi-iqdg53fkcy8qh8vmskdiu6hcymozzgkl {
  .well;
  padding: 0;
  .pane-content {
    h3 {
      padding: 12px 19px;
      font-size: @font-size-h4;
      background: #fff;
      border-top: 1px solid @well-border;
      border-bottom: 1px solid @well-border;
      margin: 0;
      //font-weight: bold;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        border-bottom: 1px solid @well-border;
        a {
          padding: 10px 19px;
          display: block !important;
          .glyphicon;
          &:before {
            margin-right: 10px;
          }
          &:hover {
            text-decoration: none;
          }
          &.facetapi-active {
            .glyphicon-remove-sign;
          }
          &.facetapi-inactive {
            .glyphicon-ok-sign;
          }
        }
      }
    }
  }
}

/* Application page */
.panels-flexible-column-31-2 {
  .panels-flexible-row {
    margin-bottom: 20px;
  }
}

.tabs-action {
  margin: 20px 0;
}

.vbo-form-wrapper {
  //border: none;
  //.box-shadow(none);
  .panel-body {
    padding: 5px;
    .btn {
      .btn-xs;
    }
  }
}

.panel-pane > .pane-content .form-item:not(.form-type-radio, .form-type-checkbox) {
  margin-bottom: 20px;
}

.filter-wrapper.panel {
  display: none;
}

.field-type-text-long > div > div > a.ckeditor_links {
  //display: none !important; // We want to show these as we trust our users.
}

*:not(div.well) .form-actions.form-wrapper.form-group {
  .well;
}

div.form-builder-element {
  margin: 0;
}

.panels-flexible-region-31-right-inside > .panel > .panel-body {
  padding: 0;
}

.view-ply-application-reviews {
  .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
  }
}

#form-builder-wrapper {
  .form-builder-title-bar {
    //width: 30px;
    //height: 30px;
    //background: #ff0;
    position: relative;
    left: 0px;
    top: 25px;
    display: block;
    //color: @grey-light;
    font-size: 25px;
    float: left;
    .form-builder-links {
      position: absolute;
      top: 0px;
      right: 10px !important;
      a {
        background: none !important;
        span {
          display: inline !important;
        }
      }
      a.remove {
        //color: #f00;
      }
    }
  }
  .form-builder-element {
    margin-left: 40px;
    margin-right: 80px;
  }
  &.form-builder-hover {

  }
}

.form-builder-placeholder {
  /*height: 30px;
  margin-top: 0px !important;
  position: relative !important;
  width: 100%;
  border: 1px solid @brand-danger;*/
}


/**
 * Company page
 */

td.views-field-views-bulk-operations {
  width: 1em;
  .checkbox {
  }
}

td.views-field-webform-submission-count-node {
  text-align: right;
}

/**
 * Questionary
 */
#webform-components-add {
  padding: 0;
  margin: 0;
  li {
    margin: 0 10px 10px 0;
    list-style: none;
    width: 160px;
    float: left;
    a {
      .btn;
      .btn-block;
      .btn-default;
    }
  }
}
#webform-components-form {
  .tabledrag-toggle-weight-wrapper {
    display: none;
  }
}

/* Reports */

#views-exposed-form-ply-applications-chart-panel-pane-3 {
  .views-widget {
    #edit-date-filter-min-wrapper {
      float: left;
    }
    #edit-date-filter-max-wrapper {
      float: left;
    }
  }
}

.pane-manage-header {
  .navbar-brand {
    background: url('../images/layout/logo-light.png') no-repeat center;
    background-size: 86px 20px;
    margin: 10px 0;
    overflow: hidden;
    text-indent: 9999px;
    font-size: 1px;
    padding: 0;
    height: 20px;
    width: 86px;
  }
  @media (min-width: 768px) {
    .navbar-nav > li > a {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.page-error {
  .navbar-header .logo {
    margin: 10px 0;
    padding: 0;
    height: 20px;
    width: 86px;
    img {
      height: 20px;
      width: 86px;
    }
  }
}

.non-bold {
  font-weight: 200;
}

.pane-node-form-title,
.panel-pane.pane-entity-form-field {
  margin-bottom: 20px;
}

.views-row.panel.panel-default {
  padding: 10px;
  > div > h3,
  > div > h2 {
    margin-top: 0;
  }
}

// Camera tag recording widget
.webform-component-cameratag {
  .ply-cameratag-widget-hidden {
    .camera_tag {
      display: none;
    }
  }
}

.icheckbox {
  border: 2px solid @gray-light;
  border-radius: 2px;
  cursor: pointer;
  height: 20px;
  position: absolute;
  width: 20px;
  z-index: 1;

  &:before {
    content: "\f00c";
    color: @gray-light;
    display: inline-block;
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 1;
    left: 50%;
    opacity: 0;
    transition: 0.1s;
    transform: translateX(-50%) translateY(-50%) scale(0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
  }

  &.checked {
    &:before {
      opacity: 1;
      transform: translateX(-50%) translateY(-50%) scale(1);
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.iradio {
  border: 2px solid @gray-light;
  border-radius: 100%;
  cursor: pointer;
  height: 20px;
  position: absolute;
  width: 20px;
  z-index: 1;

  &:before {
    content: "\f111";
    color: @gray-light;
    display: inline-block;
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 1;
    left: 50%;
    opacity: 0;
    transition: 0.1s;
    transform: translateX(-50%) translateY(-50%) scale(0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
  }

  &.checked {
    &:before {
      opacity: 1;
      transform: translateX(-50%) translateY(-50%) scale(1);
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.colorpicker {
  top: 0;
  left: 0;
  z-index: 2500;
  min-width: 130px;
  padding: 4px;
  margin-top: 1px;
  border-radius: 4px;
}

.form-type-managed-file {
  input[type=file] {
    margin-bottom: 10px;
  }
  .file {
    display: block;
    margin-bottom: 5px;

    img {
      display: none;
    }
  }
  .file-size {
    display: none;
  }
}

.file-widget.input-group {
  input[type=file] {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .modal-xl {
    width: 95%;
    max-width:1800px;
    height: 95%;

    .modal-content {
      /* 80% of window height */
      height: 95%;
    }
    .modal-body {
      /* 100% = dialog height, 120px = header + footer */
      //max-height: calc(100% - 120px);
      //overflow-y: hidden;
    }
  }
}

/** The Magic **/
.btn-breadcrumb .btn:not(:last-child):after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid white;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: 100%;
  z-index: 3;
}
.btn-breadcrumb .btn:not(:last-child):before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid rgb(173, 173, 173);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  margin-left: 1px;
  left: 100%;
  z-index: 3;
}

/** The Spacing **/
.btn-breadcrumb .btn {
  padding:6px 12px 6px 24px;
}
.btn-breadcrumb .btn:first-child {
  padding:6px 6px 6px 10px;
}
.btn-breadcrumb .btn:last-child {
  padding:6px 18px 6px 24px;
}

/** Default button **/
.btn-breadcrumb .btn.btn-default:not(:last-child):after {
  border-left: 10px solid #fff;
}
.btn-breadcrumb .btn.btn-default:not(:last-child):before {
  border-left: 10px solid #ccc;
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):after {
  border-left: 10px solid #ebebeb;
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):before {
  border-left: 10px solid #adadad;
}

/** Ply button **/
.btn-breadcrumb .btn.btn-ply:not(:last-child):after {
  border-left: 10px solid #fff;
}
.btn-breadcrumb .btn.btn-ply:not(:last-child):before {
  border-left: 10px solid #ccc;
}
.btn-breadcrumb .btn.btn-ply:hover:not(:last-child):after {
  border-left: 10px solid #ebebeb;
}
.btn-breadcrumb .btn.btn-ply:hover:not(:last-child):before {
  border-left: 10px solid #adadad;
}

.gravatar {
  border-radius: 100%;
  cursor: pointer;
  overflow: hidden;
  margin-right: 20px;
  height: 40px;
  width: 40px;

  .character {
    background: @gray-lighter;
    color: #ffffff;
    display: block;
    font-size: 18px;
    font-weight: 300;
    line-height: 38px;
    height: 40px;
    text-align: center;
    width: 40px;
  }
  img {
    width: 100%;
  }
}
