// Insert your styling here.

.navbar {
  .logo {
    margin: 10px 0;
    img {
      height: 20px;
    }
  }
}

.pane-pane-navigation {
  .navbar-collapse {
    float: left;
  }
}

.ply-co-tabs {
  margin-bottom: 0;
  border-bottom: none;
  margin-top: 20px;
  & > li.ply-main-menu-link > a {
    font-size: 1.2em;
    font-weight: 200;
  }
}

.ply-co-name {
  float: left;
  margin: 17px 20px 0 0;
  padding: 0;
}

.ply-po-name {
  margin-bottom: 20px;
}

.ply-co-settings-link {
  margin-top: 30px;
}