.ply-add-position-right,
#ply-no-positions {
  display: none;
  position: relative;
  z-index: 1;
}

.pane-node-field-company-brand-color {
  .colorpicker-element {
    i {
      height: 20px;
      width: 20px;
      vertical-align: middle;
    }
  }
}
