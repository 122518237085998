.pane-content > .comment-form .field-name-comment-body textarea {
  height: 5em;
}

.comment {
  .comment-form {
    .clearfix;
    .field-name-comment-body {
      float: left;
      textarea:focus {
        height: 8em;
      }
    }
    .form-actions {
      clear: none;
    }
  }
}
.pane-node-comments {
  .indented {
    margin-left: 20px;
    .comment {
      border: 1px solid @well-border;
      border-top: 0;
      padding: 15px;
      &:last-of-type {
        //background: #f00;
        border-radius: 0 0 @border-radius-base @border-radius-base;
      }
    }
  }
  .comment-form.indented {
    background: @well-bg;
    border: 1px solid @well-border;
    border-radius: @border-radius-base @border-radius-base 0 0;
    padding: 15px;
    .form-group {
      margin: 0;
    }
  }

}

.ply-ap-header {
  border-top: 1px solid @table-border-color;
  border-left: 1px solid @table-border-color;
  border-right: 1px solid @table-border-color;
  border-radius: @border-radius-base;
  .ply-ap-header-info {
    padding: 20px;
    h1 {
      margin: 0;
    }
  }
  .nav-tabs {
    padding: 0 20px;
  }
}

.tooltip{
  // Preview of form items in tooltip on adding question.
  .form-preview{
    padding: 5px;
    background: #111;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
    select, input, textarea{
      color: #000;
      font-weight: 400;
    }
    textarea{
      height: 80px;
      margin-top: 5px;
    }
    input[type="radio"], input[type="checkbox"]{
      margin-right: 5px;
    }
  }
}

#webform-component-edit-form{
  #edit-name{
    font-size: 16px;
    &:focus{
      border-width: 2px;
    }
  }
  .form-item-required{
    margin: 20px 0;
  }

  .add-help-text{
    display: inline-block;
  }
  .clear-help-text, .form-item-extra-description{
    display: none;
  }
  &.has-help{
    .add-help-text{
      display: none;
    }
    .clear-help-text{
      display: inline-block;
    }
    .form-item-extra-description{
      display: block;
    }
  }

  .form-option-add{
    text-align: left;
    margin-left: 35px;
    margin-top: 10px;
  }
  .option-order-cell{
    .tabledrag-changed{
      display: none;
    }
  }
  .option-actions-cell{
    .add{
      display: none;
    }
  }
}

#webform-components-form{
  .checkbox{
    position: static;
  }
}

#edit-items-options {
  border: 1px solid #e4e9ec;
  padding: 25px 10px;

  thead {
    display: none;
  }
  .form-text {
    border: 1px solid #e4e9ec;
    padding: 2px 5px;
  }
  .options-widget {
    table {
      td {
        padding: 6px;
      }
    }
  }

  &.options-error {
    border-color: @state-danger-text;
  }
}

.no-preview{
  display: none;
}

.pane-ply-webform-add-question-preview{
  position: relative;
  -webkit-transition: top 0.25s; /* Safari */
  transition: top 0.25s;
}

.preview-wrapper, .no-preview{
  display: none;
  background: #f8f8f8;
  border: 1px solid #e7e7e7;
  padding: 20px;
  textarea{
    width: 100%;
    height: 80px;
  }
  .form-items{
    &.checkbox, &.radio{
      label{
        display: block;
      }
    }
  }
}

.page-node-webform {
  .webform-component-edit {
    width: 30%;
  }
}

// Hide upload button on resume upload file field.
.field-name-field-application-resume .form-managed-file {
  display: block;
  .input-group-btn > button {
    display: none;
  }
}

.user-rating-badge{
  margin-top: 10px;
  margin-left: 5px;
}

.candidate-messages {
  display: block;
  margin-top: 10px;
  text-align: right;
}

.candidate-messages a {
  text-decoration: none;
}

.candidate-messages span.fa-envelope-o {
  color: #000;
  padding-right: 4px;
}

.m-r-sm {
  margin-right: 10px;
}
