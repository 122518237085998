.pane-ply-candidate-search-panel-pane-1 {
  .views-row {
    .form-type-checkbox {
      float: left;
      width: 30px;
      margin: 0;
      height: @line-height-large * @font-size-large;
      input {
        //position: relative;
      }
    }
    .full-name {
      font-size: @font-size-large;
      margin: 0 5px;
      float: left;
    }
    .views-field-created {
      float: right;
      line-height: @line-height-large * @font-size-large;
      font-size: @font-size-small;
    }
  }
}

// Comments
.comment {
  margin-bottom: 20px;

  .user-picture {
    img {
      display: block;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .arrow:after,
  .arrow:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
  }
  .panel.arrow.left:after,
  .panel.arrow.left:before {
    border-left: 0;
  }

  .panel.arrow.left:before {
    left: 0px;
    top: 30px;
    /*Use boarder color of panel*/
    border-right-color: inherit;
    border-width: 16px;
  }

  .panel.arrow.left:after {
    left: 1px;
    top: 31px;
    /*Change for different outline color*/
    border-right-color: #FFFFFF;
    border-width: 15px;
  }

  .comment-date {
    text-align: right;
    display: block;
  }

}

// Messages
.message {
  display: flex;
  margin: 10px 0 20px 0;

  .gravatar {
    flex: none;
    margin-right: 40px;
    height: 80px;
    width: 80px;

    .character {
      font-size: 26px;
      line-height: 80px;
      height: 80px;
      width: 80px;
    }
  }
  .panel {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
    }
    &:before {
      top: -1px;
      left: -30px;
      border-top: 30px solid #dddddd;
      border-left: 30px solid transparent;
    }
    &:after {
      top: 0;
      left: -28px;
      border-top: 30px solid #fff;
      border-left: 30px solid transparent;
    }
  }

  .message-meta {
    font-size: 70%;

    .placeholder {
      font-style: normal;
    }
  }
  .message-content {
    clear: both;
  }
}

.ply-ca-avatar {
  float: left;
  margin: 0 15px 0 0;
  height: 60px;
  width: 60px;

  .character {
    font-size: 26px;
    line-height: 60px;
    height: 60px;
    width: 60px;
  }
}

.field-name-field-application-resume {
  .file-name {
    margin: 5px 0;
  }
}

.modal-ply-resume-preview {
  .modal-title {
    .btn {
      margin-left: 10px;
    }
  }
  iframe {
    border: @btn-default-border 1px solid;
  }
}
