#plyjobs-widget-iframe-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 250px;
  iframe{
    border: none;
    overflow: hidden;
  }
}

#plyjobs-widget-toolbox{
  position: fixed;
  width: 250px;
  left: 0;
  top: 0;
  bottom: 0;
  background: #eee;
  padding: 20px;
  z-index: 29;
}

#plyjobs-widget.bottom-left .widget-btn,
#plyjobs-widget.top-left .widget-btn{
  left: 150px !important;
}

#form-company-settings{
  display: none;
}