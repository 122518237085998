.not-logged-in.page-user {
  .navbar-toggle {
    display: none !important;
  }

  .pane-page-content {
    width: 400px;
    margin: 0 auto;
    margin-top: 80px;

    > .pane-content {
      .panel;
      .panel-default;
      padding: 20px;
    }
  }

  .user-additional-links{
    margin-top: 20px;
    text-align: center;
  }
}

.page-p,
.page-c {
  a {
    transition: 0.4s;

    &:hover {
      text-decoration: none;
    }
  }
  button {
    transition: 0.4s;
  }
  .radio,
  .form-type-radio,
  .checkbox,
  .form-type-checkbox {
    label {
      padding-left: 20px;
    }
  }
  .form-item {
    .description {
      color: lighten(@gray, 20%);
      font-size: 85%;
      font-style: italic;
      margin: 10px 0 20px 0;
    }
  }
  .webform-confirmation {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 10px 0 20px 0;
    }
    p {
      margin: 10px 0;
    }
  }
  .help-block {
    color: lighten(@gray, 20%);
    font-size: 85%;
    font-style: italic;
    margin: 10px 0 20px 0;
  }
  .content_left {
    margin-bottom: 40px;

    @media screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  }

  .navbar {
    display: none;
  }
  .main-container {
    width: 100%;
    > .row {
      > .panel-panel {
        float: none;
        padding: 0;
      }
    }
  }
  .company-header {
    .cover {
      background-position: center;
      background-size: cover;
      height: 220px;

      @media screen and (min-width: 768px) {
        height: 360px;
      }
    }

    .overlay {
      height: 60px;
      position: relative;

      &:before {
        background: linear-gradient(transparent, rgba(0,0,0, 0.3));
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
      }
    }
    .company-info {
      background: #FBFBFB;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        margin-bottom: 80px;
      }

      > .container > .row {
        position: relative;

        @media screen and (min-width: 768px) {
          height: 100px;
        }
      }

      .logo {
        background: white;
        border-radius: 2px;
        box-shadow: 0px 0px 60px -15px rgba(0,0,0, 0.2);
        display: table;
        height: 160px;
        left: 50%;
        padding: 20px;
        position: absolute;
        text-align: center;
        top: 0;
        transform: translateX(-50%) translateY(-50%);
        vertical-align: middle;
        width: 160px;
        z-index: 1;

        @media screen and (min-width: 768px) {
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
        }

        .image {
          display: table-cell;
          max-width: 120px;
          vertical-align: middle;
        }
      }

      .company-details {
        padding: 100px 20px 20px;
        text-align: center;

        @media screen and (min-width: 768px) {
          float: left;
          padding: 20px 0 20px 200px;
          text-align: left;
        }

        h1 {
          margin: 0;
        }
        a {
          color: lighten(@gray, 40%);

          &:hover {
            color: @brand-primary;
          }
        }

        &.no-logo {
          padding-left: 15px;
        }
      }
    }
    .company-share {
      padding: 0 15px 20px 15px;
      text-align: center;
      vertical-align: middle;

      @media screen and (min-width: 768px) {
        display: table;
        float: right;
        height: 100px;
        padding: 20px 15px 20px 0;
        text-align: left;
      }

      h3 {
        color: lighten(@gray, 50%);
        font-size: 18px;
        margin: 0 0 10px 0;
        padding-right: 20px;
        vertical-align: middle;

        @media screen and (min-width: 768px) {
          display: table-cell;
          margin: 0;
        }
      }
      .share-buttons {
        vertical-align: middle;

        @media screen and (min-width: 768px) {
          display: table-cell;
        }
      }
    }

    .position-location {
      height: 360px;
      position: relative;

      .view-ply-position-map {
        position: relative;
        z-index: 0;
      }
      .overlay {
        height: 100%;
        left: 0;
        display: table;
        padding: 0 20px;
        top: 0;
        width: 100%;
        z-index: 1;
        position: absolute;
      }
      .overlay-text {
        display: table-cell;
        padding-bottom: 100px;
        vertical-align: bottom;
        width: 100%;

        @media screen and (min-width: 768px) {
          padding-bottom: 40px;
        }
      }

      .map-title {
        color: #ffffff;
        position: relative;
        z-index: 1;
      }
      .map-location {
        color: #ffffff;
        font-size: 12px;
        margin: 0;
        position: relative;
        z-index: 1;

        .field-type-addressfield {
          margin-bottom: 5px;

          @media screen and (min-width: 768px) {
            display: inline-block;
            margin: 0 20px 0 0;
          }
        }
        .map-show {
          border: 1px solid #ffffff;
          border-radius: 2px;
          cursor: pointer;
          display: inline-block;
          padding: 4px 10px;
          transition: 0.4s;

          &:hover {
            background: #ffffff;
            color: @gray;
          }
        }
      }

      &.remote,
      &.uncategorized {
        height: auto;
        .map-title {
          margin-top: 40px;
        }
        @media screen and (min-width: 768px) {
          height: 140px;
        }
        .overlay{
          position: relative;
          @media screen and (min-width: 768px) {
            position: absolute;
          }
        }
      }
      &.uncategorized {
        .map-title {
          margin-top: 45px;
        }
        @media screen and (min-width: 768px) {
          height: 140px;
        }
      }
    }
  }

  h2.pane-title {
    font-size: 30px;
    margin-top: 0;
  }

  .footer {
    ul {
      font-size: 85%;
      list-style: none;
      text-align: center;
      padding: 0;

      li {
        color: lighten(@gray, 40%);
        display: inline-block;

        a {
          color: lighten(@gray, 40%);

          &:hover {
            color: @brand-primary;
          }
        }

        &:after {
          background: @brand-primary;
          border-radius: 4px;
          content: '';
          display: inline-block;
          height: 4px;
          margin: 0 10px;
          width: 4px;
          vertical-align: middle;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
    p {
      text-align: center;
      font-size: 85%;
      text-transform: uppercase;
      color: lighten(@gray, 40%);
    }
  }
}

.page-p {
  .company-header .company-info {
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }
  }
  .job-listing {
    color: lighten(@gray, 40%);
    display: block;

    @media screen and (min-width: 768px) {
      width: 160px;
      margin-bottom: 20px;
    }

    &:hover {
      color: @brand-primary;
    }

    &.no-logo {
      text-align: left;
    }
  }
}

.field-name-field-company-description {
  img {
    max-width: 100%;
  }
}

.view-ply-public-company-positions {
  .page-header h3 {
    margin-top: 0;
  }

  .view-content {
    margin-bottom: 30px;

    > h3 {
      color: lighten(@gray, 40%);
      font-size: 14px;
      margin-top: 0;
      text-transform: uppercase;
    }
  }
  .views-row {
    padding: 10px 20px;
    transition: 0.4s;

    &:hover {
      background: lighten(@gray, 70%);
    }

    &.views-row-last {
      margin-bottom: 20px;
    }
  }
  .title {
    font-size: 18px;
    height: 32px;
    line-height: 30px;
    margin: 0;

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.share-buttons {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 2px;

    a {
      color: lighten(@gray, 50%);
      border: 2px solid lighten(@gray, 50%);
      border-radius: 340px;
      display: block;
      line-height: 36px;
      height: 40px;
      text-align: center;
      width: 40px;

      &:hover {
        color: @brand-primary;
        border-color: @brand-primary;
      }
    }
  }
}
