.ply-manage-po-header {
  margin-top: 15px;
  h2 {
    margin-bottom: 0;
  }
  .ply-manage-po-manage-menu {
    margin-top: 5px;
  }
}

.ply-stage-filter-link {
  //position: relative;
  /*
  }*/
}

.ply-stage-edit {
  position: relative;
  top: -30px;
  left: 10px;
  float: left;
  z-index: 10;
  &.active {
    color: #fff;
  }
  &:hover {
    color: #000;
  }
}

.ply-stages {
  .list-group-item {
    padding-left: 30px;
  }
}

.modal-open .modal.modal-ply-preview {
  overflow-x: hidden;
  overflow-y: hidden;
}

iframe.ply-preview-iframe {
  border: @btn-default-border 1px solid;
}

.modal-ply-preview{
  .preview-url{
    margin-right: 10px;
  }
}

.view-ply-company-positions {
  .views-row {
    padding: 10px 20px;
    transition: 0.4s;

    &:hover {
      background: lighten(@gray, 70%);
    }
  }

  .location {
    color: lighten(@gray, 40%);
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    margin-right: 20px;
  }
  .position-links {
    margin: 7px 0;
  }
  .title {
    font-size: 18px;
    height: 40px;
    line-height: 38px;
  }
  .badge {
    vertical-align: middle;
  }
}

// Change icon for uploading image to textarea (through One Click Upload - ocupload module).
.cke_button__ocupload_icon{
  background-image: url('/sites/all/libraries/ckeditor/plugins/icons.png') !important;
  background-position: 0 -936px !important;
}