/**
 * Applications Search
 */
.view-ply-candidate-search,
.view-ply-application-search {
  .view-filters,
  .vbo-form-wrapper,
  .vbo-select-all-markup {
    display: none !important;
  }
  .search-header {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid @gray-lighter;

    .ply-text-search {
      width: 300px;
    }
    .btn-select-all {
      padding-left: 20px;
      padding-right: 20px;

      .icheckbox {
        position: relative;
      }
    }
  }
  .views-row {
    padding: 10px 20px;
    transition: 0.4s;

    .form-type-checkbox {
      float: left;
      margin: 0 20px 0 0;
      height: 40px;
      width: 20px;

      .icheckbox {
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .name {
      font-size: 18px;
      height: 40px;
      line-height: 38px;
      margin: 0;
    }
    .rating {
      font-size: 14px;
      height: 40px;
      line-height: 38px;
      margin: 0 0 0 15px;
      color: lighten(@gray, 40%);
      .fa {
        margin-right: 1px;
      }
    }
    .created {
      color: lighten(@gray, 40%);
      font-size: 12px;
      height: 40px;
      line-height: 40px;
      width: 100px;
    }
    .resume {
      color: @gray;
      margin-right: 20px;
      height: 40px;
      line-height: 38px;
    }

    &:hover {
      background: lighten(@gray, 70%);
    }

    &.selected {
      background: lighten(@brand-primary, 50%);

      &:hover {
        background: lighten(@brand-primary, 45%);
      }
    }

    &.unseen {
      font-weight: 700;

      .name {
        font-weight: 700;
      }
    }
  }
}
